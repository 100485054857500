import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
    en: {
        label: {
            first_name: "First Name",
            last_name: "Last Name",
            number: "Number",
            name: "Name",
            status: "State",
            active: "active",
            not_active: "not active",
            start_call: "Call",
            more_information: "More information",
            save_to_phonebook: "Save to phone book",
            caller_id: "Caller ID",
            results_for: "Results for",
            pin: "PIN",
            cid_number: "Outgoing Number",
            state: "State",
            current_device: "Current Device",
            forwarding: "forwarding",
            unavailable: "unavailable",
            forwarding_target: "Forwarding Target",
            disable_forwarding: "Disable forwarding",
            forward_external_calls: "Forward external calls",
            logout_from_phone: "Logout from phone",
            login_to_phone: "Login to phone",
            login_on: "Login on",
            select_other_device: "Select other device",
            my_queues: "My Queues",
            copy_to_clipboard: "Copy to clipboard",
            external_number: "External number",
            internal_number: "Internal number",
            close: "Close",
            aliases: "Aliases",
            more_link: "more",
        },
    },
    de: {
        label: {
            first_name: "Vorname",
            last_name: "Nachname",
            number: "Nummer",
            name: "Name",
            status: "Status",
            active: "aktiv",
            not_active: "nicht aktiv",
            start_call: "Anruf starten",
            more_information: "Weitere Informationen",
            save_to_phonebook: "Im Telefonbuch speichern",
            caller_id: "Rufnummer",
            results_for: "Ergebnisse für",
            pin: "PIN",
            cid_number: "Ausgehende Nummer",
            state: "Status",
            current_device: "Aktuelles Gerät",
            forwarding: "weiterleiten",
            unavailable: "nicht erreichbar",
            forwarding_target: "Weiterleitungs Ziel",
            disable_forwarding: "Weiterleitung deaktivieren",
            forward_external_calls: "Externe Anrufe weiterleiten",
            logout_from_phone: "Von Telefon abmelden",
            login_to_phone: "An Telefon anmelden",
            login_on: "Anmelden an",
            select_other_device: "Anderes Gerät auswählen",
            my_queues: "Meine Queues",
            copy_to_clipboard: "In Zwischenablage kopieren",
            external_number: "Externe Rufnummer",
            internal_number: "Interne Rufnummer",
            close: "Schließen",
            aliases: "Aliase",
            more_link: "mehr",
        },
    },
    fr: {
        label: {
            first_name: "First Name",
            last_name: "Nom de famille",
            number: "Number",
            name: "Nom",
            status: "Etat",
            active: "active",
            not_active: "non actif",
            start_call: "Call",
            more_information: "Plus d'informations",
            save_to_phonebook: "Enregistrer",
            caller_id: "Identification de l'appelant",
            results_for: "Résultats pour",
            pin: "PIN",
            cid_number: "Numéro sortant",
            state: "État",
            current_device: "Current Device",
            forwarding: "forwarding",
            unavailable: "unavailable",
            forwarding_target: "Forwarding Target",
            disable_forwarding: "Disable forwarding",
            forward_external_calls: "Renvoyer les appels externes",
            logout_from_phone: "Déconnexion du téléphone",
            login_to_phone: "Connexion au téléphone",
            login_on: "Se connecter sur",
            select_other_device: "Sélectionner un autre appareil",
            my_queues: "Mes files d'attente",
            copy_to_clipboard: "Copier dans le presse-papiers",
            external_number: "Numéro externe",
            internal_number: "Numéro interne",
            close: "Fermer",
            alias: "Alias",
            more_link: "plus",
        },
    },
};

const dateTimeFormats = {
    en: {
        time: {
            hour: "numeric",
            minute: "numeric",
        },
        shortDay: {
            weekday: "long",
            day: "2-digit",
            month: "long",
        },
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "short",
            day: "numeric",
            weekday: "short",
            hour: "numeric",
            minute: "numeric",
        },
    },
    de: {
        time: {
            hour: "numeric",
            minute: "numeric",
        },
        shortDay: {
            weekday: "long",
            day: "2-digit",
            month: "long",
        },
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "short",
            day: "numeric",
            weekday: "short",
            hour: "numeric",
            minute: "numeric",
        },
    },
    fr: {
        time: {
            hour: "numeric",
            minute: "numeric",
        },
        shortDay: {
            weekday: "long",
            day: "2-digit",
            month: "long",
        },
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "short",
            day: "numeric",
            weekday: "short",
            hour: "numeric",
            minute: "numeric",
        },
    },
};

const i18n = new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    messages,
    dateTimeFormats,
});

export default i18n;
